import VectorSource from 'ol/source/Vector'
import LineString from 'ol/geom/LineString'
import Feature from 'ol/Feature'
import VectorLayer from 'ol/layer/Vector'
import {Style, Fill, Stroke} from 'ol/style'
import { createFeatures } from './createFeatures.js'

var historyLayer

export function drawHistory(rawpositions,map,view){
    if (typeof historyLayer !=='undefined'){
        map.removeLayer(historyLayer)
    }
    const vectorTrack = new VectorSource({});
    vectorTrack.addFeatures(createFeatures(rawpositions));
    historyLayer = new VectorLayer({
      source: vectorTrack,
      style: new Style({
        fill: new Fill({ color: '#C15652', weight: 1 }),
        stroke: new Stroke({ color: '#C15652', width: 3 })
      })
    })
    map.addLayer(historyLayer)
}