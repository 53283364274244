import VectorSource from 'ol/source/Vector'
import Feature from 'ol/Feature'
import {Style,Icon} from 'ol/style'
import VectorLayer from 'ol/layer/Vector'
import {getHeading, toRadians} from './getHeading.js'
import Point from 'ol/geom/Point'
import { fromLonLat,toLonLat } from 'ol/proj'

const offsetIcon = 90 //degrees
export var lastPositionLayer
var stateFocus = false

export function drawIcon(rawpositions,map,view){
    if (typeof lastPositionLayer !=='undefined'){
        map.removeLayer(lastPositionLayer)
      }
    const lastPosition = fromLonLat(rawpositions[rawpositions.length-1])
    focus = lastPosition
    var heading
    if (rawpositions.length>1){
      heading = getHeading(fromLonLat(rawpositions[rawpositions.length-2]),lastPosition)
    }
    else{
      heading = 90
    }
   
    var lastPositionSrc = new VectorSource()
    const wgs84=toLonLat(lastPosition)
    const label="Lat: "+wgs84[1].toFixed(5)+", Lon: "+wgs84[0].toFixed(5)
    var lastPositionFeature=new Feature({geometry: new Point([lastPosition[0],lastPosition[1]]),name:label})
    lastPositionSrc.addFeature(lastPositionFeature)
    
    lastPositionLayer = new VectorLayer({
      source: lastPositionSrc,
      style: new Style({
        image: new Icon({
          rotation: toRadians(heading-offsetIcon),
          src: './aircraft-red.png',
          scale: 0.15,
        }),
      })
    }) 
    map.addLayer(lastPositionLayer)
    if(stateFocus===false){
      view.setCenter(focus)
      stateFocus=true
    }
}