import Point from 'ol/geom/Point'

export function getHeading(point1,point2){
    // coordinates back to wgs84
    var coordinate1 = new Point(point1)
    var coordinate2 = new Point(point2)

    coordinate1.transform('EPSG:900913','EPSG:4326')
    coordinate2.transform('EPSG:900913','EPSG:4326')
    point1=coordinate1.getCoordinates()
    point2=coordinate2.getCoordinates()

    // calculate hdg
    var deltaL=point2[0]-point1[0]
    var X=Math.cos(toRadians(point2[1]))*Math.sin(toRadians(deltaL))
    var Y=Math.cos(toRadians(point1[1]))*Math.sin(toRadians(point2[1]))-Math.sin(toRadians(point1[1]))*Math.cos(toRadians(point2[1]))*Math.cos(toRadians(deltaL))
    var heading = Math.atan2(X,Y)*180/Math.PI
    if (heading<0){
        heading=heading+360
    }
    return heading
}

export function toRadians(coordinate){
    return coordinate*Math.PI/180
}