import Feature from 'ol/Feature'
import LineString from 'ol/geom/LineString'
import { fromLonLat } from 'ol/proj';

export function createFeatures(points) {
    var pointsSplitted = [];
    var pointsArray = [];
    pointsSplitted.push(points[0]);
    var lastLambda = points[0][0];
    
    for (var i = 1; i < points.length; i++) {
      var lastPoint = points[i - 1];
      var nextPoint = points[i];
      if (Math.abs(nextPoint[0] - lastLambda) > 180) {
        var deltaX = xToValueRange(nextPoint[0] - lastPoint[0]);
        var deltaY = nextPoint[1] - lastPoint[1];
        var deltaXS = xToValueRange(180 - nextPoint[0]);
        var deltaYS;
        if (deltaX === 0) {
          deltaYS = 0;
        } else {
          deltaYS = deltaY / deltaX * deltaXS;
        }
        var sign = lastPoint[0] < 0 ? -1 : 1;
        pointsSplitted.push([180 * sign, nextPoint[1] + deltaYS]);
        pointsArray.push(pointsSplitted);
        pointsSplitted = [];
        pointsSplitted.push([-180 * sign, nextPoint[1] + deltaYS]);
      }
      pointsSplitted.push(nextPoint);
      lastLambda = nextPoint[0];
    }
    pointsArray.push(pointsSplitted);
    var geom1 = new Array()
    var geom2 = new Array()
    var temp1 = pointsArray[0]
    var temp2 = pointsArray[1]
    for(var i = 0; i<temp1.length; i++){
        geom1[i]=fromLonLat(temp1[i])
    }
    if(typeof temp2=='undefined'){
      var feature = [new Feature({
        geometry: new LineString(geom1)
      })]
    }
    else{
      for (var i=0; i<temp2.length;i++){
        geom2[i]=fromLonLat(temp2[i])
      }
      var feature = [new Feature({
        geometry: new LineString(geom1)
      }),
      new Feature({
        geometry: new LineString(geom2)
      })]
    }

    return feature;
    
  }
  
  function xToValueRange(x) {
    if (Math.abs(x) > 180) {
      var sign = x < 0 ? -1 : 1;
      return x - 2 * 180 * sign;
    } else {
      return x;
    }
  }