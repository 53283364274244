import VectorSource from 'ol/source/Vector'
import { createFeatures } from './createFeatures.js'
import {Fill, Stroke,Circle,Style,Icon} from 'ol/style'
import VectorLayer from 'ol/layer/Vector'
import { getLastHistoryPosition } from './getFlightHistory'
import { drawIcon } from './drawIcon'

var stateFocus = false
const offsetIcon = 90 //degrees
var trackLine
var vectorTrack
var trackLayer

export function drawTrack(rawpositions,map,view){
    if (typeof trackLayer !=='undefined'){
      map.removeLayer(trackLayer)
    }
    // insert last position of history to have one single line
    const lastPosition = getLastHistoryPosition()
    rawpositions.splice(0,0,lastPosition)
    vectorTrack = new VectorSource({});
    vectorTrack.addFeatures(createFeatures(rawpositions));
    trackLayer = new VectorLayer({
      source: vectorTrack,
      style: new Style({
        fill: new Fill({ color: '#C15652', weight: 1 }),
        stroke: new Stroke({ color: '#C15652', width: 3 })
      })
    })
    map.addLayer(trackLayer)
    drawIcon(rawpositions,map,view)   
  }