import Circle from 'ol/style/Circle'
import {Fill, Stroke, RegularShape, Style, Text} from 'ol/style'

export function getTextStyle(feature,type) {
    var fill = new Fill({color: '#A4A4A4'})
    var stroke = new Stroke({color: '#303030',width: 3})
    var text = new Text({
        font: '15px Arial',
        text: feature.get('name'),
        offsetY: -17,
        placement: 'line',
        fill: new Fill({
            color: '#000'
            }),
        stroke: new Stroke({
            color: '#fff',
            width: 3
        })
    })
    if(type==='square'){
        return [
        new Style({
            image: new RegularShape({
                fill: fill,
                stroke: stroke,
                points: 4,
                radius: 10,
                angle: Math.PI / 4,
                }),
            stroke: stroke,
            text: text  
        })
        ]
    }
    else{
        return[
            new Style({
                image: new Circle({
                    fill: new Fill({color: '#D0CF7F'}),//new Fill({color: '#A3B59B'}),
                    stroke: stroke,
                    radius: 8
                }),
                stroke: stroke,
                //text: text  
            }) 
        ]
    }
  }