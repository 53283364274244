import axios from 'axios'
import {fromLonLat} from 'ol/proj'

const historyUrl = 'https://api.genharts.ch/gethistory'
var lastHistoryPosition

export function getLastHistoryPosition(){
  
  return lastHistoryPosition
}

export async function getFlightHistory(callback,map,view){
  return await new Promise((resolve,reject)=>{
    axios.get(historyUrl).then(response =>{
      const rawpositions = new Array()
      var counter=0
      response.data.forEach((flight)=>{
          flight['positions'].forEach((position,index)=>{
            rawpositions[counter+index]=[position['lon'],position['lat']]
          })
          counter=counter+flight['positions'].length
      })
      lastHistoryPosition=rawpositions[rawpositions.length-1]
      callback(rawpositions,map,view)
      resolve()
    })
  })  
  
}