import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import Overlay from 'ol/Overlay'
import XYZ from 'ol/source/XYZ'
import VectorSource from 'ol/source/Vector';
import {Fill, Stroke, Style} from 'ol/style'
import Feature from 'ol/Feature'
import Point from 'ol/geom/Point';
import {getTextStyle} from './components/getTextStyle.js'
import {readTextFile} from './components/readTextFile.js'
import {getFlightTrack,getLastUpdate,getLastAltitude} from './components/getFlightTrack.js'
import {drawTrack} from './components/drawTrack.js'
import {createFeatures} from './components/createFeatures.js'
import { getCoordinates } from './components/getCoordinates'
import { getFlightHistory } from './components/getFlightHistory';
import { drawHistory } from './components/drawHistory';
import { lastPositionLayer } from './components/drawIcon';

// for map
var focus

// Baselayer
const baseLayer = new TileLayer({
  type: 'base',
  source: new XYZ({
    attributions: ['Application © by <a href="https://genhart-it-solutions.ch" target="_blank">Genhart IT Solutions</a>,','powered by Esri',
                   'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community,'],
    attributionsCollapsible: false,
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',  // World_Topo_Map
    maxZoom: 23
  })
})

const view = new View({
  center: [0,0],
  zoom: 8
})

const map = new Map({
  target: 'map',
  layers: [
    baseLayer,
  ],
  view: view
})

// Add Route to map
readTextFile("./route.json", function(text){
  var data = JSON.parse(text); //parse JSON
  var routeRawLatLon=new Array()
  data.features[0].geometry['coordinates'].forEach((coordinate,index)=>{
    if(coordinate[0]>180){
      coordinate[0]=coordinate[0]-360
    }    
    routeRawLatLon[index]=[coordinate[0],coordinate[1]]
  })
  
  var routeSrc = new VectorSource()
  routeSrc.addFeatures(createFeatures(routeRawLatLon))
  const routeLayer = new VectorLayer({
    source: routeSrc,
    style: new Style({
      fill: new Fill({ color: '#303030', weight: 1 }),
      stroke: new Stroke({ color: '#303030', width: 3 })
    })
  }) 
  map.addLayer(routeLayer)

  // add markers
  var markers = data.features.slice(1)
  var markerFeatures = new Array()
  markers.forEach((element,index)=>{
    const coordinate=element.geometry['coordinates']
    const label = element.properties['name']
    markerFeatures[index]=new Feature({geometry: new Point(getCoordinates(coordinate[0],coordinate[1])),name: label})
    markerFeatures[index].setStyle(getTextStyle(markerFeatures[index],'square'))
  })
  const markerSrc = new VectorSource()
  markerSrc.addFeatures(markerFeatures)
  const markerLayer = new VectorLayer({
    source: markerSrc
  })
  map.addLayer(markerLayer)
});

// add initiatives
var initiativesLayer
readTextFile("./initiatives.json",function(file){
  var data = JSON.parse(file)
  var initiativesFeatures = new Array()
  data.features.forEach((element,index)=>{
    const coordinates = element.geometry['coordinates']
    const label = element.properties['name']
    initiativesFeatures[index]=new Feature({geometry: new Point(getCoordinates(coordinates[0],coordinates[1])),name: label})
    initiativesFeatures[index].setStyle(getTextStyle(initiativesFeatures[index],'circle'))
  })
  const initiativesSrc = new VectorSource()
  initiativesSrc.addFeatures(initiativesFeatures)
  initiativesLayer = new VectorLayer({
    source: initiativesSrc
  })
  map.addLayer(initiativesLayer)

})

// draw history
getFlightHistory(drawHistory,map,view).then(()=>{
  // when completed draw current track
  getFlightTrack(drawTrack,map,view)
})

setInterval(refreshMap,30000)

function refreshMap(){
  //refresh sources
  getFlightTrack(drawTrack,map,view)
}

// Popup stuff
var contentInit = document.getElementById("popup")
var contentAC = document.getElementById("popup-aircraft")

var popupInitiatives = new Overlay({
  element: contentInit,
  offset: [9,9]
})

var popupAircraft = new Overlay({
  element: contentAC,
  ofset: [9,9]
})

map.addOverlay(popupInitiatives)
map.addOverlay(popupAircraft)

map.on('pointermove',(evt)=>{
  handleEvt(evt,map)
})

map.on('singleclick',(evt)=>{
  handleEvt(evt,map)
})
  
function handleEvt(evt,map){
  const coordinate = evt.coordinate

  var featuresInit=new Array()
  var featuresAC=new Array()
  var lastPositionFeature
  map.forEachFeatureAtPixel(evt.pixel,(feature,layer)=>{
      if(layer===initiativesLayer){  
        featuresInit.push(feature)  
      }
      if(layer===lastPositionLayer){
        featuresAC.push(feature)
      }
  })

  if(featuresAC.length>0){
    const name=featuresAC[0]['values_']['name']
    const now=new Date()
    const timeSinceUpdate=Math.floor((now-getLastUpdate())/1000/60)
    const altString="Altitude: FL "+getLastAltitude()
    var timeString=""
    if(timeSinceUpdate>60){
      const timeInH=Math.floor(timeSinceUpdate/60)
      if(timeInH>24){
        const timeInDay=Math.floor(timeInH/24)
        var ending=""
        if(timeInDay===1){
          ending=" day ago"
        }
        else{
          ending=" days ago"
        }
        timeString="Last Update: "+timeInDay+ending
      }
      else{
      timeString="Last Update: "+timeInH+" h ago"
      }
    }
    else if(Number.isNaN(timeSinceUpdate)){
      timeString="Last Update: not available"
    }
    else{
      switch(timeSinceUpdate){
        case 0: timeString="Last Update: just now"
                break
        case 1: timeString="Last Update: "+timeSinceUpdate+" Minute ago"
                break
        default: timeString="Last Update: "+timeSinceUpdate+" Minutes ago"
                break
      }
    }
    contentAC.innerHTML='<p><b>HB-RTT</b><br>'+name+'<br>'+altString+'<br>'+timeString+'</p>'
    popupAircraft.setPosition(coordinate)
  }
  else{
    contentAC.innerHTML=''
    popupAircraft.setPosition(undefined)
  }
  
  if(featuresInit.length>0){
    const name = featuresInit[0]['values_']['name']
    contentInit.innerHTML='<p>Initiative:<br>'+name+'</p>'
    popupInitiatives.setPosition(coordinate)
  }
  else{
      contentInit.innerHTML=''
      popupInitiatives.setPosition(undefined)
  }
}
