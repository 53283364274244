import axios from 'axios'
import {fromLonLat} from 'ol/proj'
import { getFlightHistory } from './getFlightHistory'
import { drawHistory } from './drawHistory'

const track_url = 'https://api.genharts.ch/getlasttrack'
var firstPosition
var lastUpdate
var lastAltitude
export function getLastUpdate(){
  return lastUpdate
}

export function getLastAltitude(){
  if(lastAltitude<0){
    lastAltitude=Math.abs(lastAltitude)
  }
  return lastAltitude
}

export async function getFlightTrack(callback,map,view){
    axios.get(track_url).then(response =>{
        const rawpositions = new Array()
        var counter=0
        response.data.forEach((flight)=>{
            flight['positions'].forEach((position,index)=>{
              rawpositions[counter+index]=[position['lon'],position['lat']]
            })
            counter=counter+flight['positions'].length
        })
        lastUpdate=response.data[0]['lastUpdate']
        const flights=response.data[0]['positions']
        const lastFlight=flights[flights.length-1]
        lastAltitude=lastFlight['alt']
        // Detect change in flights, when so the history is updated
        if(typeof firstPosition =='undefined'){
          if(rawpositions.length>0){
            firstPosition=rawpositions[0]
          }
        }
        if(rawpositions.length<1||firstPosition[0]!==rawpositions[0][0]||firstPosition[1]!==rawpositions[0][1]){
            getFlightHistory(drawHistory,map,view).then(()=>{
                if(rawpositions>0){
                  firstPosition=rawpositions[0]
                }
                callback(rawpositions,map,view)
            })
          }
          else{
            callback(rawpositions,map,view)
          }
      })
}